const wishType = [
  { key: 1, value: "困难求助" },
  { key: 2, value: "吐槽不满" },
  { key: 3, value: "其他" },
];

const auditStatusMap = [
  { key: 1, value: "审核通过", class: "out" },
  { key: 2, value: "审核驳回", class: "reback" },
  { key: 0, value: "待审核", class: "" },
];

export { wishType, auditStatusMap };
