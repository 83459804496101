let origin = "https://future.zhuneng.cn";
//发表运动打卡
const postHealthUrl = `/placeApi/front/healthRecord/add`;
//我的运动打卡
const myHealthUrl = `/placeApi/front/healthRecord/mineList`;
//运动打卡详情
const healthDetailUrl = `/placeApi/front/healthRecord/detailInfo`;
//社区共建墙
const wishWallUrl = `/placeApi/front/communityConstruction/list`;
export { postHealthUrl, myHealthUrl, healthDetailUrl, wishWallUrl };
